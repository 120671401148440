import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';

const LandingPage: React.FC<{ location: Location }> = ({ location }) => {
  useEffect(() => {
    navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}${location.search}`);
  });

  return null;
};

export default LandingPage;
